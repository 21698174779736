import { globalConfig, paginationConfig } from 'app/configs';
import {
  ICompany,
  ICompanyServerResponse,
  ICustomerCompany,
  ICustomerCompanyServerResponse,
} from 'app/types/company';
import {
  IPaginationQuery,
  IPaginationQueryServer,
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import { isEmpty, mappingPaginationServerToClient } from 'app/utils/helper';
import queryString from 'query-string';
import { apiWrapper, axiosClientOwner } from './axiosClient';

const { DEFAULT_PAGE, DEFAULT_LIMIT } = paginationConfig;

/**
 * INSIDE COMPANIES ================ START
 */
export type TFetchCompaniesArgs = IPaginationQuery;
export type TFetchCompaniesRes = IPaginationResponse<ICompany>;
export type TCreateCompanyArgs = {
  name: string;
  logo: any;
  slogan: string;
};
export type TCreateCompanyRes = string;
export type TUpdateCompanyArgs = {
  id: number;
  name: string;
  logo: any;
  slogan: string;
  address?: string;
  postalCode?: string;
  email?: string;
  website?: string;
  telephone?: string;
};
export type TUpdateCompanyRes = string;
export type TDeleteCompanyArgs = {
  id: number;
};
export type TDeleteCompanyRes = string;

type ApiFetchCompaniesArgs = IPaginationQueryServer;
type ApiFetchCompaniesRes = IPaginationServerResponse<ICompanyServerResponse>;
type ApiCreateCompanyArgs = {
  company_name: string;
  slogan: string;
  logo: any;
};
type ApiCreateCompanyRes = string;
type ApiUpdateCompanyArgs = {
  id: number;
  company_name: string;
  slogan: string;
  logo: any;
  address?: string;
  postal_code?: string;
  email?: string;
  website?: string;
  telephone?: string;
};
type ApiUpdateCompanyRes = string;
type ApiDeleteCompanyArgs = {
  id: number;
};
type ApiDeleteCompanyRes = string;

const fetchCompanies = async (
  query: TFetchCompaniesArgs
): Promise<TFetchCompaniesRes> => {
  const queryObject: ApiFetchCompaniesArgs = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
  };
  const url = queryString.stringifyUrl({
    url: '/api/companies',
    query: queryObject as any,
  });
  const res = await apiWrapper.get<ApiFetchCompaniesRes>(url);
  const paginatedCompanies: IPaginationResponse<ICompany> = {
    totalPages: res.last_page,
    page: res.current_page,
    limit: res.per_page,
    totalDocs: res.total,
    docs: res.data.map(docItem => mappingCompanyServer(docItem)),
  };

  return paginatedCompanies;
};

const createCompany = async (
  param: TCreateCompanyArgs
): Promise<TCreateCompanyRes> => {
  const res = await apiWrapper.post<ApiCreateCompanyArgs, ApiCreateCompanyRes>(
    '/api/companies/create',
    {
      company_name: param.name,
      slogan: param.slogan,
      logo: param.logo,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return res;
};

const updateCompany = async (
  param: TUpdateCompanyArgs
): Promise<TUpdateCompanyRes> => {
  let url: string = '/api/companies/update';

  const res = await apiWrapper.post<ApiUpdateCompanyArgs, ApiUpdateCompanyRes>(
    url,
    {
      id: param.id,
      company_name: param.name,
      slogan: param.slogan,
      logo: param.logo,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return res;
};

const updateSystemCompany = async (
  param: TUpdateCompanyArgs
): Promise<TUpdateCompanyRes> => {
  let url: string = '/api/companies/update/owner';

  let bodyData = new FormData();

  bodyData.append('id', param.id.toString());
  bodyData.append('company_name', param.name.toString());
  bodyData.append('slogan', param.slogan.toString());
  bodyData.append('logo', param.logo);
  bodyData.append('address', param.address || '');
  bodyData.append('email', param.email || '');
  bodyData.append('website', param.website || '');
  bodyData.append('telephone', param.telephone || '');

  const res = await axiosClientOwner.post<
    ApiUpdateCompanyArgs,
    ApiUpdateCompanyRes
  >(url, bodyData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-CSRF-TOKEN': document.querySelector(
        '[name~=csrf-token][content]'
      ) as any,
    },
  });

  return res;
};

const deleteCompany = async (
  param: TDeleteCompanyArgs
): Promise<TDeleteCompanyRes> => {
  const res = await apiWrapper.post<ApiDeleteCompanyArgs, ApiDeleteCompanyRes>(
    '/api/companies/delete',
    {
      id: param.id,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return res;
};
/**
 * INSIDE COMPANIES ================ END
 */

/**
 * OUTSIDE COMPANIES ================ START
 */
export type TFetchCustomerCompaniesArgs = IPaginationQuery;
export type TFetchCustomerCompaniesRes = IPaginationResponse<ICustomerCompany>;
export type TCreateCustomerCompanyArgs = {
  name: string;
  maxStations: number;
  privateKey: string;
  domain: string;
};
export type TCreateCustomerCompanyRes = string;
export type TUpdateCustomerCompanyArgs = {
  id: number;
  name: string;
  maxStations: number;
  privateKey: string;
  domain: string;
};
export type TUpdateCustomerCompanyRes = string;
export type TDeleteCustomerCompanyArgs = {
  id: number;
};
export type TDeleteCustomerCompanyRes = string;
type ApiFetchCustomerCompaniesArgs = IPaginationQueryServer;
type ApiFetchCustomerCompaniesRes =
  IPaginationServerResponse<ICustomerCompanyServerResponse>;
type ApiCreateCustomerCompanyArgs = {
  company_name: string;
  max_stations: number;
  private_key: string;
  domain: string;
};
type ApiCreateCustomerCompanyRes = string;
type ApiUpdateCustomerCompanyArgs = {
  id: number;
  company_name: string;
  max_stations: number;
  private_key: string;
  domain: string;
};
type ApiUpdateCustomerCompanyRes = string;
type ApiDeleteCustomerCompanyArgs = {
  id: number;
};
type ApiDeleteCustomerCompanyRes = string;

const fetchCustomerCompanies = async (
  query: TFetchCustomerCompaniesArgs
): Promise<TFetchCustomerCompaniesRes> => {
  const queryObject: ApiFetchCustomerCompaniesArgs = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
  };
  const url = queryString.stringifyUrl({
    url: '/api/customer_companies',
    query: queryObject as any,
  });
  const res = await apiWrapper.get<ApiFetchCustomerCompaniesRes>(url);

  return mappingPaginationServerToClient<
    ICustomerCompanyServerResponse,
    ICustomerCompany
  >({ paginationData: res, mappingFunc: mappingCustomerCompanyServer });
};
const createCustomerCompany = async (
  params: TCreateCustomerCompanyArgs
): Promise<TCreateCustomerCompanyRes> => {
  const res = await apiWrapper.post<
    ApiCreateCustomerCompanyArgs,
    ApiCreateCustomerCompanyRes
  >(
    '/api/customer_companies',
    {
      company_name: params.name,
      max_stations: params.maxStations,
      private_key: params.privateKey,
      domain: params.domain,
    },
    {
      contentType: 'multipart/form-data',
    }
  );
  return res;
};
const updateCustomerCompany = async (
  params: TUpdateCustomerCompanyArgs
): Promise<TUpdateCustomerCompanyRes> => {
  const res = await apiWrapper.put<
    ApiUpdateCustomerCompanyArgs,
    ApiUpdateCustomerCompanyRes
  >(
    '/api/customer_companies',
    {
      id: params.id,
      company_name: params.name,
      max_stations: params.maxStations,
      private_key: params.privateKey,
      domain: params.domain,
    },
    {
      contentType: 'application/json',
    }
  );
  return res;
};
const deleteCustomerCompany = async (
  params: TDeleteCustomerCompanyArgs
): Promise<TDeleteCustomerCompanyRes> => {
  const res = await apiWrapper._delete<
    ApiDeleteCustomerCompanyArgs,
    ApiDeleteCustomerCompanyRes
  >(
    '/api/customer_companies',
    {
      id: params.id,
    },
    {
      contentType: 'application/json',
    }
  );
  return res;
};
/**
 * OUTSIDE COMPANIES ================ END
 */

const companyAPI = {
  fetchCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  updateSystemCompany,
  fetchCustomerCompanies,
  createCustomerCompany,
  updateCustomerCompany,
  deleteCustomerCompany,
};

export const mappingCompanyServer = (
  companyServer: ICompanyServerResponse
): ICompany => {
  const result: ICompany = {
    id: companyServer.id,
    name: companyServer.company_name,
    logo: companyServer.logo
      ? `${globalConfig.BASE_URL}${companyServer.logo}`
      : null,
    slogan: companyServer.slogan,
    address: isEmpty(companyServer.address) ? '' : companyServer.address,
    postalCode: isEmpty(companyServer.postal_code)
      ? ''
      : companyServer.postal_code,
    email: isEmpty(companyServer.email) ? '' : companyServer.email,
    website: isEmpty(companyServer.website) ? '' : companyServer.website,
    telephone: isEmpty(companyServer.telephone) ? '' : companyServer.telephone,
    createdAt: new Date(companyServer.created_at),
    updatedAt: new Date(companyServer.updated_at),
  };

  return result;
};

export const mappingCustomerCompanyServer = (
  customerCompanyServer: ICustomerCompanyServerResponse
): ICustomerCompany => {
  const result: ICustomerCompany = {
    id: customerCompanyServer.id,
    name: customerCompanyServer.company_name,
    maxStations: customerCompanyServer.max_stations,
    privateKey: customerCompanyServer.private_key,
    domain: customerCompanyServer.domain,
    createdAt: new Date(customerCompanyServer.created_at),
    updatedAt: new Date(customerCompanyServer.updated_at),
  };

  return result;
};

export default companyAPI;
